import * as React from "react"
import {Container, Row, Col} from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHorizontalRule } from '@fortawesome/pro-regular-svg-icons'
import {Link} from 'gatsby';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container>

      <Row>
        <Col className="d-flex flex-column justify-content-center" style={{minHeight:400}}>
          <h2><span className="text-gradient">404:</span> Not Found</h2>
          <p>Oeps! Deze pagina bestaat helaas niet.</p>
          <div className="button-wrapper">
            <Link to="/" className="btn btn-solid btn-menu-item btn-loyall d-inline-block">
              Ga naar homepagina
              <FontAwesomeIcon icon={faHorizontalRule} />
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
    
  </Layout>
)

export default NotFoundPage
